var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "login d-flex justify-center" },
    [
      _c(
        "v-main",
        { staticClass: "d-flex" },
        [
          _c(
            "v-layout",
            {
              staticClass: "h-100",
              attrs: {
                fluid: "",
                flex: "",
                "flex-wrap": "",
                "justify-center": "",
              },
            },
            [
              _c(
                "v-flex",
                {
                  staticClass: "white",
                  attrs: {
                    "d-flex": "",
                    xs12: "",
                    sm5: "",
                    "align-center": "",
                    "elevation-0": "",
                  },
                },
                [
                  _c(
                    "v-card",
                    {
                      staticClass: "v-login-box rounded-0",
                      attrs: { flat: "", width: "100%" },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "d-flex mb-16 pb-3 back-to-screen" },
                        [
                          _c(
                            "a",
                            {
                              staticClass: "text-decoration-none black--text",
                              attrs: { href: "/login" },
                            },
                            [
                              _c(
                                "v-icon",
                                {
                                  staticClass: "black--text",
                                  attrs: { medium: "", left: "" },
                                },
                                [_vm._v(" arrow_back ")]
                              ),
                              _vm._v(" Back "),
                            ],
                            1
                          ),
                        ]
                      ),
                      _c(
                        "v-layout",
                        { staticClass: "flex-column v-login-info text-left" },
                        [
                          _c(
                            "h1",
                            {
                              staticClass: "font-weight-medium mb-2 black-text",
                            },
                            [_vm._v(_vm._s(_vm.$t("forgotPass.forPass")))]
                          ),
                          _c("p", { staticClass: "mt-0" }, [
                            _vm._v(_vm._s(_vm.$t("forgotPass.typeEmail"))),
                          ]),
                        ]
                      ),
                      _c(
                        "v-card-text",
                        { staticClass: "py-4 px-0" },
                        [
                          true
                            ? _c("p", [_c("strong", [_vm._v(_vm._s(_vm.msg))])])
                            : _vm._e(),
                          _c(
                            "v-form",
                            {
                              ref: "form",
                              attrs: {
                                action: _vm.action,
                                method: "POST",
                                role: "form",
                              },
                              model: {
                                value: _vm.valid,
                                callback: function ($$v) {
                                  _vm.valid = $$v
                                },
                                expression: "valid",
                              },
                            },
                            [
                              _c("input", {
                                attrs: { type: "hidden", name: "csrf_token" },
                                domProps: { value: _vm.csrf_token },
                              }),
                              _c(
                                "div",
                                { staticClass: "field" },
                                [
                                  _c(
                                    "label",
                                    { staticClass: "black--text mb-2 d-flex" },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$t("forgotPass.emailAdd"))
                                      ),
                                    ]
                                  ),
                                  _c("v-text-field", {
                                    staticClass: "rounded-0",
                                    attrs: {
                                      placeholder: "Enter your e-mail address",
                                      name: "email",
                                      required: "",
                                      outlined: "",
                                      dense: "",
                                      rules: _vm.emailRules,
                                    },
                                    model: {
                                      value: _vm.email,
                                      callback: function ($$v) {
                                        _vm.email =
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                      },
                                      expression: "email",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-dialog",
                                {
                                  attrs: {
                                    persistent: "",
                                    "max-width": "600px",
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "activator",
                                      fn: function (ref) {
                                        var on = ref.on
                                        var attrs = ref.attrs
                                        return [
                                          _c(
                                            "v-btn",
                                            _vm._g(
                                              _vm._b(
                                                {
                                                  staticClass:
                                                    "primary white--text mb-7 rounded-0",
                                                  attrs: {
                                                    type: "submit",
                                                    block: "",
                                                    depressed: "",
                                                    large: "",
                                                    disabled: !_vm.valid,
                                                    name: "method",
                                                    value: "link",
                                                  },
                                                },
                                                "v-btn",
                                                attrs,
                                                false
                                              ),
                                              _vm.off
                                            ),
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("forgotPass.submit")
                                                )
                                              ),
                                            ]
                                          ),
                                        ]
                                      },
                                    },
                                  ]),
                                  model: {
                                    value: _vm.dialog,
                                    callback: function ($$v) {
                                      _vm.dialog = $$v
                                    },
                                    expression: "dialog",
                                  },
                                },
                                [
                                  _c(
                                    "v-card",
                                    { staticClass: "pa-16" },
                                    [
                                      _c(
                                        "v-card-text",
                                        [
                                          _c(
                                            "v-container",
                                            { staticClass: "text-center" },
                                            [
                                              _c(
                                                "v-row",
                                                {
                                                  staticClass: "mb-6",
                                                  attrs: { justify: "center" },
                                                },
                                                [
                                                  _c("v-img", {
                                                    attrs: {
                                                      "max-height": "173",
                                                      "max-width": "173",
                                                      src: require("../assets/images/success-check.svg"),
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "h2",
                                                {
                                                  staticClass:
                                                    "pb-4 font-weight-medium black--text",
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "forgotPass.chckEmail"
                                                      )
                                                    )
                                                  ),
                                                ]
                                              ),
                                              _c("p", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t("forgotPass.para")
                                                  )
                                                ),
                                              ]),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-card-actions",
                                        { staticClass: "justify-center mt-7" },
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                width: "164",
                                                color: "primary",
                                                depressed: "",
                                                large: "",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  _vm.dialog = false
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t("forgotPass.close")
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c("p", { staticClass: "mt-3" }, [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("forgotPass.alreadyAcc")) +
                                " "
                            ),
                            _c("a", { attrs: { href: "/login" } }, [
                              _c("u", [
                                _vm._v(_vm._s(_vm.$t("forgotPass.signIn"))),
                              ]),
                            ]),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-flex",
                {
                  staticClass: "v-login-right style-2",
                  attrs: {
                    xs12: "",
                    sm7: "",
                    "elevation-0": "",
                    blue: "",
                    "accent-4": "",
                  },
                },
                [_c("h2", [_vm._v("Win every search.")])]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }