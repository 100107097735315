<template flex>
  <div class="login d-flex justify-center">
    <v-main class="d-flex">
        <v-layout fluid flex flex-wrap justify-center class="h-100">
          <v-flex d-flex xs12 sm5 align-center elevation-0 class="white">
            <v-card flat class="v-login-box rounded-0" width="100%">

                <div class="d-flex mb-16 pb-3 back-to-screen">
                  <a href="/login" class="text-decoration-none black--text">
                    <v-icon medium left class="black--text"> arrow_back </v-icon> Back
                  </a>
                </div>

                <v-layout class="flex-column v-login-info text-left">
                    <h1 class="font-weight-medium mb-2 black-text">{{ $t('forgotPass.forPass') }}</h1>
                    <p class="mt-0">{{ $t('forgotPass.typeEmail') }}</p>
                </v-layout>

                <v-card-text class="py-4 px-0">
                    <p v-if="true">
                        <strong>{{ msg }}</strong>
                    </p>
                    <v-form v-model="valid" ref="form" :action="action" method="POST" role="form">
                        <input type="hidden" name="csrf_token" :value="csrf_token">
                        <div class="field">
                          <label class="black--text mb-2 d-flex">{{ $t('forgotPass.emailAdd') }}</label>
                          <v-text-field
                              placeholder="Enter your e-mail address"
                              v-model.trim="email"
                              name="email"
                              required
                              outlined
                              class="rounded-0"
                              dense
                              :rules="emailRules"
                          ></v-text-field>
                        </div>

                        <v-dialog
                            v-model="dialog"
                            persistent
                            max-width="600px"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                    <v-btn type='submit'
                                      block
                                      depressed large
                                      v-bind="attrs"
                                      v-on="off"
                                      class="primary white--text mb-7 rounded-0"
                                      :disabled="!valid"
                                      name="method"
                                      value="link"
                                    >{{ $t('forgotPass.submit') }}</v-btn>
                            </template>
                            <v-card class="pa-16">
                                <v-card-text>
                                    <v-container class="text-center">
                                        <v-row justify="center" class="mb-6">
                                            <v-img
                                            max-height="173"
                                            max-width="173"
                                            src="../assets/images/success-check.svg"
                                            ></v-img>
                                        </v-row>
                                        <h2 class="pb-4 font-weight-medium black--text">{{ $t('forgotPass.chckEmail') }}</h2>
                                        <p>{{ $t('forgotPass.para') }}</p>
                                    </v-container>
                                </v-card-text>
                                <v-card-actions class="justify-center mt-7">
                                    <v-btn
                                        width="164"
                                        color="primary"
                                        depressed
                                        large
                                        @click="dialog = false"
                                    >
                                        {{ $t('forgotPass.close') }}
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                    </v-form>
                    <p class="mt-3">
                      {{ $t('forgotPass.alreadyAcc') }} <a href="/login"><u>{{ $t('forgotPass.signIn') }}</u></a>
                    </p>
                </v-card-text>
            </v-card>
          </v-flex>
          <v-flex xs12 sm7 elevation-0 blue accent-4 class="v-login-right style-2">
            <h2>Win every search.</h2>
          </v-flex>
        </v-layout>
    </v-main>
  </div>
</template>

<script>
import Vue from 'vue';
import { FlowService } from '@services/flow.service';
import { API_ORY_URL } from '@config'
export default Vue.extend({
  name: 'Recovery',
  components: {
  },
    data () {
    return {
      email: "",
      valid:null,
      flow:null,
      action:null,
      csrf_token : null,
      showmsg: false,
      dialog:false,
      msg:"",
      inputEmail: 'Input your E-Mail Id here',
      off: false,
      emailRules: [
        v => !!v || 'Email is required',
        v => /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || 'E-mail must be valid',
        v => !(/\s/.test(v)) || 'Email cannot contain spaces',
      ],
    }
  },
  methods: {
    buildAction: function (){
        this.msg = "";
        this.flow = this.$route.query.flow;
    },

    goToLogin : function () {
      return this.$router.push('login');
    },

    /* getRecoveryFlowStatus: async function (){
        await FlowService.flow({ flowId: this.flow, type:'recovery' })
        .then((res) => {
          this.action = res.data.action;
          this.csrf_token = res.data.token;
          if(res.data.state == 'choose_method'){
            if(res.data.message && res.data.message[0] && res.data.message[0].id == 4060004){
              //this.msg=res.data.message[0].text;
              this.msg='Recovery link expired or already used. Try again'
            }else{
              this.msg='Please enter your registered E-mail Id'
            }
            this.showmsg = true;            
          }
          if(res.data.state == 'sent_email'){
            this.showmsg = true;
            this.msg=res.data.message[0].text;
          }
        })
        .catch((err) => {
          //console.log(err)
        });
    }, */

    getRecoveryFlowStatus: async function (){
      await FlowService.fetchFlow(this.flow, 'recovery')
      .then(async (flowResp) => {
        if(flowResp.error){
          if(flowResp.error.code === 410){
            this.msg='Session expired'
            this.showmsg = true;
            window.location.href = API_ORY_URL + 'self-service/recovery/browser'
          }
          if(flowResp.error.code === 401){
            this.msg='Session invalid'
            this.showmsg = true;
            return
          }
        }
        this.action = flowResp.ui.action;
        this.csrf_token = flowResp.ui.nodes[0].attributes.value
        if(flowResp.state == 'choose_method'){
          if(flowResp.ui.messages && flowResp.ui.messages[0] && flowResp.ui.messages[0].id == 4060004){
            //this.msg=res.data.message[0].text;
            this.msg='The link expired or already used. Try recovery'
          }else{
            this.msg='Please enter your registered E-mail Id'
          }
          this.showmsg = true;            
        }
        if(flowResp.state == 'sent_email'){
          this.showmsg = true;
          if(flowResp.ui.messages && flowResp.ui.messages[0] && flowResp.ui.messages[0].id === 1060002){
            this.msg=flowResp.ui.messages[0].text
          }          
        }
      })
      .catch((err) => {
        //console.log(err)
      });
    }
  },

  beforeMount(){
     this.$root.$emit('signInOpen');
    this.buildAction();
    // this.getRecoveryFlowStatus()
    this.getRecoveryFlowStatus()
    
 },
});
</script>
<style scoped>

.v-main{
  height: 100vh;
  
}
  
  @media only screen and (max-width:601px){
 .v-application .v-main  {
    padding-left: 0px !important;
  
  
}
 }
</style>
